<template>
  <AuthLayoutContent>
    <template v-slot:left>
      <div class="step d-flex flex-column mx-auto">
        <div class="alert alert-danger" v-if="error">{{ error }}</div>
        <div class="alert alert-danger" v-if="!connected && connecting">
          {{ $t("Digid not connected") }}
        </div>

        <UWVStep1
          v-if="step == 1 && uwv"
          :connected="connected"
          @previous="previous"
          @next="next"
        ></UWVStep1>

        <div class="w-100" v-if="step == 1 && !uwv">
          <img :src="digid_img" class="w-75 mb-4" alt="" />
          <h1>{{ $t("Identification MijnOverheid") }}</h1>
          <p class="fw-medium text-gray">
            {{
              $t(
                "To verify your identity, we ask you to log in to DigiD. Your login details will be removed from our server immediately after identification."
              )
            }}
          </p>

          <strong class="text-black">
            {{ $t("What data do we retreive?") }}
          </strong>
          <ul class="mt-2">
            <li class="text-gray list-style-dots-inside">
              {{ $t("Your personal details") }}
            </li>
            <li class="text-gray list-style-dots-inside">
              {{ $t("Your annual income") }}
            </li>
            <li class="text-gray list-style-dots-inside">
              {{ $t("Your current address") }}
            </li>
          </ul>
          <p class="text-danger fw-bold text-capitalize mt-1" v-if="getErrors.profile">
            {{ getErrors.profile }}
          </p>

          <div class="w-100 mt-4">
            <button
              class="btn btn-digid btn-block p-0 d-flex justify-content-between"
              @click="step = 2"
              :disabled="!connected"
            >
              <img src="../../../../assets/images/onboarding/digid_logo.png" alt="" />
              <div class="m-auto">Login met DigiD</div>
              <div></div>
            </button>
            <button class="btn btn-outline-white btn-block mt-3" @click="previous">
              {{ $t("Go back") }}
            </button>
          </div>
        </div>

        <div class="w-100" v-if="step == 2">
          <img :src="digid_img" class="w-75 mb-4" alt="" />
          <h1>{{ $t("How do you want to login?") }}</h1>
          <p class="fw-medium text-gray">
            {{ $t("Select the prefered method below to log in to DigiD.") }}
          </p>

          <div
            class="digidAction cursor-pointer py-2 pr-3 mb-3 bg-white d-none d-lg-flex d-xl-flex d-xl-none"
            v-if="isMobile == false"
            @click="next({ action: 'qr' })"
          >
            <div class="w-20 d-flex">
              <img
                src="../../../../assets/images/onboarding/digid_qr.png"
                class="m-auto"
                alt=""
              />
            </div>
            <div class="w-80">
              <div class="d-flex justify-content-between">
                <strong class="text-black">
                  {{ $t("Using the DigiD app") }}
                </strong>
                <span class="badge badge-primary p-2">Recommended</span>
              </div>
              <div>
                {{ $t("The easiest and safest way of logging in") }}
              </div>
            </div>
          </div>

          <div
            class="digidAction cursor-pointer py-2 mb-3 bg-white d-flex"
            @click="next({ action: 'sms' })"
          >
            <div class="w-20 d-flex">
              <img
                src="../../../../assets/images/onboarding/digid_sms.png"
                class="m-auto"
                alt=""
              />
            </div>
            <div class="w-80">
              <strong class="text-black">{{
                $t("Using the sms verification code")
              }}</strong>
              <div>
                {{ $t("Log in using your credentials and validate using an SMS code") }}
              </div>
            </div>
          </div>

          <div class="d-block d-lg-none">
            <p class="text-gray">
              {{
                $t(
                  "Logging in with the DigiD app only works if you on a computer or laptop. You can't use the QR scan code from your own mobile device."
                )
              }}
            </p>
          </div>

          <button class="btn btn-outline-white btn-block" @click="previous">
            {{ $t("Go back") }}
          </button>
        </div>

        <QRLogin
          v-if="step == 3 && digid_login_type == 'qr'"
          @previous="previous"
          @next="next"
          :uwv="uwv"
          :submitted="submitted"
        ></QRLogin>

        <SMSLogin
          v-if="step == 3 && digid_login_type == 'sms'"
          @previous="previous"
          @next="next"
          :uwv="uwv"
          :submitted="submitted"
          :signing_in="signing_in"
        ></SMSLogin>

        <SMSLoginOTP
          v-if="step == 4 && digid_login_type == 'sms'"
          @previous="previous"
          @next="next"
          :uwv="uwv"
          :submitted="submitted"
          :signing_in="signing_in"
        ></SMSLoginOTP>

        <QRCode
          v-if="step == 4 && digid_login_type == 'qr'"
          :qr_image="qr_image"
          @previous="previous"
          @next="next"
          :uwv="uwv"
          :submitted="submitted"
        ></QRCode>

        <div class="w-100" v-if="step == 5">
          <img :src="uwv_img" class="w-75 mb-4" v-if="uwv" alt="" />
          <img :src="digid_img" class="w-75 mb-4" v-else alt="" />
          <h1>
            {{ $t("Retrieving data...") }}
          </h1>
          <p class="text-gray" v-if="digid_login_type == 'sms'">
            {{
              $t(
                "We successfully connected to your DigiD-account. Please wait a moment while we retreive and validate all necessary data."
              )
            }}
          </p>
          <p class="text-gray" v-if="!uwv && digid_login_type == 'qr'">
            {{
              $t(
                "Once you scanned the QR, you will be asked to login using your app. Pleas press ‘Login’ and wait until all data is loaded."
              )
            }}
          </p>
          <p class="text-gray" v-else-if="digid_login_type == 'qr'">
            {{ $t("Please wait until all data is loaded.") }}
          </p>
          <Spinner />
        </div>

        <div class="w-100" v-if="step == 6">
          <img :src="uwv_img" class="w-75 mb-4" v-if="uwv" alt="" />
          <img :src="digid_img" class="w-75 mb-4" v-else alt="" />
          <h1>
            {{ $t("Great, your information has been succesfully received!") }}
          </h1>
          <p class="text-gray">You are logged out.</p>
          <div class="w-100 mt-4">
            <button class="btn btn-primary btn-block" @click="next">
              {{ $t("Next step") }}
            </button>
            <!-- <button class="btn btn-outline-white btn-block" @click="previous">
                {{ $t("Go back") }}
              </button> -->
          </div>
        </div>
      </div>
    </template>
    <template v-slot:right>
      <div class="step__rightSection mx-auto d-none d-lg-block d-xl-block">
        <div class="text-center" v-if="step == 3 && digid_login_type == 'qr'">
          <img
            src="../../../../assets/images/onboarding/digid_qr_code.png"
            class="w-75"
            alt=""
          />
        </div>
        <div class="text-center" v-else-if="step == 4 && digid_login_type == 'qr'">
          <img
            src="../../../../assets/images/onboarding/digid_qr_scan.png"
            class="w-75"
            alt=""
          />
        </div>
        <div class="text-center" v-else-if="step == 5 && !uwv">
          <img
            src="../../../../assets/images/onboarding/digid_fetching.png"
            class="w-75"
            alt=""
          />
        </div>
        <div class="text-center" v-else-if="step == 5 && uwv">
          <img
            src="../../../../assets/images/onboarding/uwv_fetching.png"
            class="w-75"
            alt=""
          />
        </div>
        <div v-else>
          <RightPoints v-if="uwv" active="5" />
          <RightPoints v-else active="4" />
        </div>
      </div>
    </template>
  </AuthLayoutContent>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AuthLayoutContent from "@/components/common/AuthLayoutContent.vue";
import { useSocketIO } from "@/socketio";
import Spinner from "@/components/ui/Spinner.vue";
import RightPoints from "../../Expat/RightPoints.vue";
import UWVStep1 from "./UWVStep1.vue";
import QRLogin from "./QRLogin.vue";
import QRCode from "./QRCode.vue";
import SMSLogin from "./SMSLogin.vue";
import SMSLoginOTP from "./SMSLoginOTP.vue";

export default {
  data() {
    return {
      step: 1,
      phone_number: "",
      submitted: false,
      signing_in: false,
      otp_success: false,
      connecting: false,
      qr_image: null,
      error: null,
      socket: null,
      form: {
        action: "",
        code: "",
        username: "",
        password: "",
        otp: "",
      },
      uwv_img: require("../../../../assets/images/onboarding/uwv.png"),
      digid_img: require("../../../../assets/images/onboarding/digid.png"),
    };
  },
  components: {
    AuthLayoutContent,
    Spinner,
    RightPoints,
    UWVStep1,
    QRLogin,
    QRCode,
    SMSLogin,
    SMSLoginOTP,
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("loadingError", ["getLoadings", "getErrors"]),
    ...mapGetters("tenant", ["tenant"]),
    connected() {
      return this.socket && this.socket.connected;
    },
    uwv() {
      return this.$route.name == "DigidUWV";
    },
    digid_login_type() {
      if (this.uwv) return localStorage.getItem("digid_login_type");

      return this.form.action;
    },
    isMobile() {
      const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i,
      ];

      return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
      });
      // return window.innerWidth <= 800 && window.innerHeight <= 600;
    },
  },
  async mounted() {
    let tenant_id = this.$route.params.tenant_id;
    if (tenant_id) {
      await this.getTenant(tenant_id);
    }

    if (this.uwv && !this.digid_login_type) {
      this.$router.push(`/user/digid/${this.tenant.id}`);
    }

    if (this.uwv) {
      this.form.action = this.digid_login_type;
    }

    if (this.tenant.encrypted_id) {
      this.connectSocket();
    } else {
      setTimeout(() => {
        this.connectSocket();
      }, 2000);
    }

    setTimeout(() => {
      if (this.connected == false) {
        if (this.uwv) {
          this.$router.push(`/user/digid/uwv/${this.tenant.id}/failed`);
        } else {
          this.$router.push(`/user/digid/${this.tenant.id}/failed`);
        }
      }
    }, 4000);
    console.log("mounted1");
  },
  watch: {
    $route() {
      this.step = 1;
      this.signing_in = false;
    },
  },
  methods: {
    ...mapActions("tenant", ["updateTenant", "getTenant"]),
    numberUpdated(event, object) {
      this.form.phone_number = object.number;
    },
    connectSocket() {
      console.log("connect");
      const { socket } = useSocketIO({
        tenant_id: this.tenant.encrypted_id,
        original_tenant_id: this.tenant.id,
      });

      this.socket = socket;
      socket.on("error", (data) => {
        console.log("error", data);
        this.error = data.message;
        this.signing_in = false;
        this.step = 1;

        if (this.uwv) {
          this.$router.push(`/user/digid/uwv/${this.tenant.id}/failed`);
        } else {
          this.$router.push(`/user/digid/${this.tenant.id}/failed`);
        }
      });

      socket.on("error_message", (data) => {
        console.log("error", data);
        this.error = data.message;
        this.signing_in = false;
        this.step = 1;
      });

      socket.on("data", (data) => {
        console.log("Data", data);

        if (data.qr_image) {
          this.qr_image = data.qr_image;
        }

        if (data.logged_in) {
          this.signing_in = false;
          this.step++;
        }

        if (data.qr_scanned) {
          this.qr_image = null;
          this.next();
        }

        if (data.otp_success) {
          this.otp_success = true;
          this.step++;
        }

        if (data.done != undefined) {
          this.data_fetched = true;
          this.next();
        }
        console.log("this.step", this.step);
      });

      socket.on("connect", () => {
        console.log("connect");
        console.log(socket.id); // undefined
      });

      socket.on("disconnect", () => {
        console.log("disconnect");
        console.log(socket.id); // undefined
      });
      this.connecting = true;
    },
    previous() {
      if (this.step == 1) {
        this.$router.go(-1);
      } else {
        this.step--;
      }
    },
    next(data = {}) {
      this.submitted = true;
      console.log("this.step", this.step);

      if (this.step == 2) {
        this.form.action = data.action;
        localStorage.setItem("digid_login_type", data.action);
      }

      if (this.step == 3) {
        this.form.code = data.code;
        console.log("data.code", data, this.form);
        if (this.form.action == "qr") {
          if (!this.form.code) {
            return;
          }

          this.socket.emit("login", {
            is_uwv: this.uwv,
            code: this.form.code,
          });
        }

        if (this.form.action == "sms") {
          this.form.username = data.username;
          this.form.password = data.password;
          if (!this.form.username || !this.form.password) {
            return;
          }

          this.socket.emit("login", {
            type: "sms",
            is_uwv: this.uwv,
            username: this.form.username,
            password: this.form.password,
          });
          this.signing_in = true;
          return;
        }
      }

      if (this.step == 4 && this.form.action == "sms") {
        this.form.otp = data.otp;
        this.socket.emit("otp", {
          type: "sms",
          is_uwv: this.uwv,
          otp: this.form.otp,
        });
        this.signing_in = true;
        return;
      }

      if (this.step == 6) {
        if (this.uwv) {
          this.$router.push({
            name: "ExpatStep11",
            params: { tenant_id: this.tenant.id },
          });
        } else if (this.tenant.profession == "entrepreneur") {
          this.$router.push({
            name: "ExpatStep7",
            params: { tenant_id: this.tenant.id },
          });
        } else {
          this.$router.push({
            name: "DigidUWV",
            params: { tenant_id: this.tenant.id },
          });
        }

        return;
      }
      this.step++;
      this.submitted = false;
      this.error = null;

      if (this.uwv && this.step == 2) {
        this.step = 3;
      }
    },
  },
};
</script>

<style lang="scss">
body {
  @include md {
    background: white;
  }
}

.step {
  max-width: 412px;

  @include md {
    padding-top: 62px;
  }

  @include xs {
    max-width: 90%;
  }

  &__rightSection {
    width: 362px;

    @include xs {
      width: 93%;
    }
  }
}

.vue-tel-input {
  padding: 5px;
}

.digidAction {
  border: 1px solid #e1e1e1;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}

.digidAction:hover {
  background: #e1e1e1 !important;
}
</style>
