<template>
  <div class="w-100">
    <img
      src="../../../../assets/images/onboarding/uwv.png"
      class="w-75 mb-4"
      alt=""
      v-if="uwv"
    />
    <img
      src="../../../../assets/images/onboarding/digid.png"
      class="w-75 mb-4"
      alt=""
      v-else
    />
    <h1>
      {{ $t("Vul hieronder je DigiD gebruikersnaam en wachtwoord in") }}
    </h1>
    <b-form @submit.prevent="next">
      <TextInput
        name="username"
        :label="$t('Digid gebruikersnaam')"
        v-model="form.username"
        rules="required"
      />
      <div
        class="text-danger"
        v-if="['.com', '@', '.nl'].some((el) => form.username.includes(el))"
      >
        Let op! Gebruik je DigiD gebruikersnaam i.p.v. e-mailadres
      </div>

      <label for=""> Digid wachtwoord </label>
      <div class="input-group">
        <input
          name="password"
          v-model="form.password"
          :type="show_password ? 'text' : 'password'"
          class="form-control"
          aria-label="Dollar amount (with dot and two decimal places)"
        />
        <span class="input-group-text"
          ><i class="fa-regular fa-eye" @click="show_password = !show_password"></i
        ></span>
      </div>
      <button
        class="btn btn-primary btn-block mt-4"
        :disabled="!form.username || !form.password || signing_in"
        @click="next()"
      >
        Deel jouw gegevens veilig via DigiD
        <b-spinner v-if="signing_in" small label="Spinning" variant="white" />
      </button>
    </b-form>

    <button class="btn btn-outline-white btn-block mt-3" @click="previous">
      {{ $t("Go back") }}
    </button>
  </div>
</template>
<script>
import TextInput from "@/components/ui/form/controls/TextInput.vue";

export default {
  props: ["connected", "submitted", "signing_in", "uwv"],
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
      show_password: false,
    };
  },
  methods: {
    previous() {
      this.$emit("previous");
    },
    next() {
      this.$emit("next", this.form);
    },
  },
  components: { TextInput },
};
</script>
