<template>
  <div class="w-100">
    <img
      src="../../../../assets/images/onboarding/uwv.png"
      class="w-75 mb-4"
      alt=""
      v-if="uwv"
    />
    <img
      src="../../../../assets/images/onboarding/digid.png"
      class="w-75 mb-4"
      alt=""
      v-else
    />

    <h1>Vul sms-code in</h1>

    <b-form @submit="next">
      <!-- <TextInput name="otp" label="SMS-Code" v-model="form.otp" /> -->
      <div class="d-flex my-5">
        <input
          type="text"
          maxlength="1"
          class="code"
          v-model="otp1"
          id="otp1"
          @keyup="focusOTP($event, 1)"
        />
        <input
          type="text"
          maxlength="1"
          class="code"
          v-model="otp2"
          id="otp2"
          @keyup="focusOTP($event, 2)"
        />
        <input
          type="text"
          maxlength="1"
          class="code"
          v-model="otp3"
          id="otp3"
          @keyup="focusOTP($event, 3)"
        />
        <div class="desh">-</div>
        <input
          type="text"
          maxlength="1"
          class="code"
          v-model="otp4"
          id="otp4"
          @keyup="focusOTP($event, 4)"
        />
        <input
          type="text"
          maxlength="1"
          class="code"
          v-model="otp5"
          id="otp5"
          @keyup="focusOTP($event, 5)"
        />
        <input
          type="text"
          maxlength="1"
          class="code"
          v-model="otp6"
          id="otp6"
          @keyup="focusOTP($event, 6)"
        />
      </div>
    </b-form>

    <button
      class="btn btn-primary btn-block mt-4"
      :disabled="!otp || otp.length < 6 || signing_in"
      @click="next()"
    >
      {{ $t("Confirm") }}
      <b-spinner v-if="signing_in" small label="Spinning" variant="white" />
    </button>

    <button class="btn btn-outline-white btn-block" @click="previous">
      {{ $t("Go back") }}
    </button>
  </div>
</template>
<script>
export default {
  props: ["connected", "submitted", "uwv", "signing_in"],
  data() {
    return {
      otp1: "",
      otp2: "",
      otp3: "",
      otp4: "",
      otp5: "",
      otp6: "",
    };
  },
  computed: {
    otp() {
      return `${this.otp1}${this.otp2}${this.otp3}${this.otp4}${this.otp5}${this.otp6}`;
    },
  },
  mounted() {
    document.getElementById("otp1").focus();
  },
  methods: {
    previous() {
      this.$emit("previous");
    },
    next() {
      this.$emit("next", { otp: this.otp });
    },
    focusOTP($event, current) {
      if ($event.code == "Backspace") {
        current--;
      } else {
        current++;
      }
      
      if (current >= 1 && current <= 6) {
        document.getElementById("otp" + current).focus();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
input.code {
  width: 40px;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #dedede;
  margin-right: 15px;
  text-align: center;
  text-transform: uppercase;
}

.desh {
  margin: auto 20px auto 5px;
}
</style>
