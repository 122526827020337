<template>
  <div>
    <img src="../../../../assets/images/onboarding/uwv.png" class="w-75 mb-4" alt="" />
    <h1>{{ $t("Income validation MijnUWV") }}</h1>
    <p class="fw-medium text-gray">
      {{
        $t(
          "In the same way as verifiying your identity, we can also validate your income via MijnUWV. Please repeat the previous steps."
        )
      }}
    </p>

    <strong class="text-black">
      {{ $t("What data do we retreive?") }}
    </strong>
    <ul class="mt-2">
      <li class="text-gray list-style-dots-inside">
        {{ $t("Employer's declaration") }}
      </li>
      <li class="text-gray list-style-dots-inside">
        {{ $t("Pay slips last 3 months") }}
      </li>
      <li class="text-gray list-style-dots-inside">
        {{ $t("Benefit specification") }}
      </li>
    </ul>

    <div class="w-100 mt-4">
      <button
        class="btn btn-digid btn-block p-0 d-flex justify-content-between"
        @click="next()"
        :disabled="!connected"
      >
        <img src="../../../../assets/images/onboarding/digid_logo.png" alt="" />
        <div class="m-auto">Login met DigiD</div>
        <div></div>
      </button>
      <button class="btn btn-outline-white btn-block mt-3" @click="previous">
        {{ $t("Go back") }}
      </button>
    </div>
  </div>
</template>
<script>
export default {
  props: ["connected"],
  methods: {
    previous() {
      this.$emit("previous");
    },
    next() {
      this.$emit("next");
    },
  },
};
</script>
