<template>
  <div class="w-100">
    <img
      src="../../../../assets/images/onboarding/uwv.png"
      class="w-75 mb-4"
      alt=""
      v-if="uwv"
    />
    <img
      src="../../../../assets/images/onboarding/digid.png"
      class="w-75 mb-4"
      alt=""
      v-else
    />
    <h1>{{ $t("Koppelcode DigiD app") }}</h1>
    <p class="fw-medium text-gray">
      {{
        $t(
          "Open your DigiD app and enter your pincode. Next up, press the button ‘Need a pairing code?’ Enter the prompted pairing code below"
        )
      }}
    </p>

    <div class="d-flex">
      <input
        type="text"
        maxlength="1"
        class="code"
        v-model="code1"
        id="code1"
        @keyup="focusCode($event, 1)"
      />
      <input
        type="text"
        maxlength="1"
        class="code"
        v-model="code2"
        id="code2"
        @keyup="focusCode($event, 2)"
      />
      <div class="desh">-</div>
      <input
        type="text"
        maxlength="1"
        class="code"
        v-model="code3"
        id="code3"
        @keyup="focusCode($event, 3)"
      />
      <input
        type="text"
        maxlength="1"
        class="code"
        v-model="code4"
        id="code4"
        @keyup="focusCode($event, 4)"
      />
    </div>
    <!-- <b-form-input
      name="code"
      :placeholder="$t('Vul koppelcode in')"
      rules="required"
      v-model="form.code"
    /> -->
    <div class="text-danger" v-if="submitted && !code">
      {{ $t("This field is required") }}
    </div>

    <button
      class="btn btn-primary btn-block mt-4"
      @click="next()"
      :disabled="code.length != 4"
    >
      {{ $t("Confirm") }}
    </button>

    <button class="btn btn-outline-white btn-block" @click="previous">
      {{ $t("Go back") }}
    </button>
  </div>
</template>
<script>
export default {
  props: ["connected", "submitted", "uwv"],
  data() {
    return {
      code1: "",
      code2: "",
      code3: "",
      code4: "",
    };
  },
  computed: {
    code() {
      return `${this.code1}${this.code2}${this.code3}${this.code4}`;
    },
  },
  mounted() {
    document.getElementById("code1").focus();
  },
  methods: {
    previous() {
      this.$emit("previous");
    },
    next() {
      this.$emit("next", { code: this.code });
    },
    focusCode($event, current) {
      if ($event.code == "Backspace") {
        current--;
      } else {
        current++;
      }

      if (current >= 1 && current <= 4) {
        document.getElementById("code" + current).focus();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
input.code {
  width: 40px;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #dedede;
  margin-right: 15px;
  text-align: center;
  text-transform: uppercase;
}

.desh {
  margin: auto 20px auto 5px;
}
</style>
