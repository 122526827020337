<template>
  <div class="w-100">
    <img
      src="../../../../assets/images/onboarding/uwv.png"
      class="w-75 mb-4"
      alt=""
      v-if="uwv"
    />
    <img
      src="../../../../assets/images/onboarding/digid.png"
      class="w-75 mb-4"
      alt=""
      v-else
    />
    <h1>
      {{ $t("Scan the QR-code") }}
    </h1>
    <p class="fw-medium text-gray">
      {{
        $t(
          "After providing the pairing code, please press ‘next’. Your camera app will open, please scan the QR below. After scanning, complete the action by pressing ‘Login’."
        )
      }}
    </p>
    <Spinner v-if="!qr_image" />
    <img :src="qr_image" v-else alt="" class="mx-auto my-4" />
  </div>
</template>
<script>
import Spinner from "@/components/ui/Spinner.vue";

export default {
  props: ["connected", "submitted", "qr_image", "uwv"],
  data() {
    return {};
  },
  methods: {
    previous() {
      this.$emit("previous");
    },
    next() {
      this.$emit("next");
    },
  },
  components: { Spinner },
};
</script>
