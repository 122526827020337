import { io } from "socket.io-client";

export const useSocketIO = (metadata = []) => {
    let url = "https://node.huurscreen.nl";
    // let url = "http://localhost:3000";
    const socket = io(url, {
        query: {
            api_key: process.env.VUE_APP_DIGID_API_KEY,
            metadata: JSON.stringify(metadata),
        },
    });
    return {
        socket,
    };
};
